import React from "react";
import {
  Box,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";


function Popover7() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

      <Box p={4} display={{ md: "flex" }}>

        <Box mt={{ base: 4, md: 0 }} ml={{ md: 48 }}>
          <Box maxW="40rem">
            <Link
              mt={1}
              display="block"
              fontSize="2xl"
              lineHeight="normal"
              fontWeight="semibold"
              href=" # "
            >
              Deterministic Transactions
            </Link>
            <br />

            <Popover>
              <PopoverTrigger>
                <Box
                  color="white"
                  tabIndex="0"
                  role=" button "
                  aria-label=" MetaGame Box "
                  p={3}
                  w="100%"
                  bg="blue.500"
                  fontWeight="semibold"
                  children="Learn About Deterministic Transactions"
                  borderRadius="5px"
                />
              </PopoverTrigger>
              <PopoverContent bg="purple" color="white">
                <PopoverHeader fontSize="sm"  fontWeight="semibold">                   
                Deterministic Transactions
                <br />
                --- scroll down                
                </PopoverHeader>
                <PopoverArrow bg="pink.500" />
                <PopoverCloseButton bg="purple.500" />
                <PopoverBody fontSize="xl">
                Deterministic Transactions                
                </PopoverBody>
                <PopoverBody fontSize="xs">
                  A deterministic transaction ensures that the same operational steps in a specific order result in the exact the same result as anybody else who follows the exact process.
                  <br />
                  Determinism in Computer Science refers to a system where the future state of the system is entirely determined by the prior state.
                  <br />
                  At a high level, a deterministic wallet consists of a master secret key msk together with a matching master public key mpk and a deterministic key derivation procedure. At setup, the master public key is given to the hot wallet, whereas the master secret key is kept on the cold wallet.
                  <br />
                  After setup, the hot and cold wallet can independently generate matching session keys using the key derivation procedure and their respective master keys. Using this approach, we only need to store a single (master) key on the hot/cold wallet in order to generate an arbitrary number of (one-time) session keys.
                  <br />
                  Informally, a deterministic wallet should offer two main security guarantees. First, an unforgeability property, which ensures that as long as the cold wallet is not compromised, signatures to authenticate new transactions can not be forged, and thus funds are safe.
                  <br />
                  Second, an unlinkability property, which guarantees that public keys generated from the same master public key mpk are computationally indistinguishable from freshly generated public keys.
                  <br />
                  https://eprint.iacr.org/2019/698.pdf
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <br />
          </Box>

        </Box>
      </Box>
    </ div>

  );
}
export default Popover7;
